export default {
  ali: {
    name: 'Ali Moridnejad',
    title: {
      en: 'AI Research Scientist, GENAIZ',
      fr: 'AI Research Scientist, GENAIZ',
    },
    bio: {
      en:
        'Acting as AI Research Scientist at GENAIZ, Ali provides Artificial Intelligence knowledge and expertise to support development of the GENAIZ AI-driven innovation platform. Ali draws upon his Ph.D, his M.Sc. and his 12 years of data-driven problem-solving experience to develop rigorous mathematical and statistical learning models to discover the knowledge from big datasets. His AI expertise is a competitive advantage for GENAIZ customers.',
      fr:
        'Acting as AI Research Scientist at GENAIZ, Ali provides Artificial Intelligence knowledge and expertise to support development of the GENAIZ AI-driven innovation platform. Ali draws upon his Ph.D, his M.Sc. and his 12 years of data-driven problem-solving experience to develop rigorous mathematical and statistical learning models to discover the knowledge from big datasets. His AI expertise is a competitive advantage for GENAIZ customers.',
    },
  },
  susha: {
    name: 'Susha Suresh',
    title: {
      en: 'AI Researcher – NLP, GENAIZ',
      fr: 'AI Researcher – NLP, GENAIZ',
    },
    bio: {
      en:
        'With experience working in academia, biomedical and financial institutions, Susha is a skilled Artificial Intelligence engineer. Building upon the skills learned while completing her Masters Degree in Computer Science, Susha focuses on research and development in the areas of machine learning, deep learning, natural language processing, statistical modeling, and predictive analysis',
      fr:
        'With experience working in academia, biomedical and financial institutions, Susha is a skilled Artificial Intelligence engineer. Building upon the skills learned while completing her Masters Degree in Computer Science, Susha focuses on research and development in the areas of machine learning, deep learning, natural language processing, statistical modeling, and predictive analysis',
    },
  },
  wei: {
    name: 'Wei Wan',
    title: {
      en: 'Senior AI Software Engineer, GENAIZ',
      fr: 'Senior AI Software Engineer, GENAIZ',
    },
    bio: {
      en:
        'As Senior AI Engineer at GENAIZ, Wei Wan puts her expertise into developing and implementing high quality software solution in complex systems. With a M.Sc. in Quality Systems Engineering, a Ph.D. in Computer Engineering and 15 years experience conducting AI, ML and NLP research, Wei Wan has published 7 journal papers, 5 conference papers and a book chapter on model checking agent communication. GenAIz customers benefit from Wei Wan’s expertise working with unstructured document data and understanding Agile SDLC model for developing software applications to fine tune new tools, technologies and methodologies.',
      fr:
        'As Senior AI Engineer at GENAIZ, Wei Wan puts her expertise into developing and implementing high quality software solution in complex systems. With a M.Sc. in Quality Systems Engineering, a Ph.D. in Computer Engineering and 15 years experience conducting AI, ML and NLP research, Wei Wan has published 7 journal papers, 5 conference papers and a book chapter on model checking agent communication. GenAIz customers benefit from Wei Wan’s expertise working with unstructured document data and understanding Agile SDLC model for developing software applications to fine tune new tools, technologies and methodologies.',
    },
  },
  helene: {
    name: 'Hélène Labelle',
    title: {
      en: 'AI Researcher – NLP, GENAIZ',
      fr:
        'Chercheuse scientifique en traitement automatique des langues (NLP), GENAIZ',
    },
    bio: {
      en:
        'Chercheuse scientifique dans le domaine du traitement automatique du langage (TAL/NLP) et dans l’extraction de l’information (IE), Hélène conçoit et déploie des solutions d’intelligence artificielle multilingues pour les clients de GENAIZ. Grâce à son doctorat portant sur l’étude des langues et des sciences cognitives, elle aide GENAIZ à intégrer le français dans ses logiciels. Hélène a publié 6 articles de revues scientifiques et un chapitre de livre sur les défis métalinguistiques que rencontrent la langue française. Elle a participé en tant que conférencière invitée à deux événements et a présenté ses recherches sociolinguistiques et lexicographiques lors de 15 conférences en Europe et en Amérique.',
      fr:
        'Chercheuse scientifique dans le domaine du traitement automatique du langage (TAL/NLP) et dans l’extraction de l’information (IE), Hélène conçoit et déploie des solutions d’intelligence artificielle multilingues pour les clients de GENAIZ. Grâce à son doctorat portant sur l’étude des langues et des sciences cognitives, elle aide GENAIZ à intégrer le français dans ses logiciels. Hélène a publié 6 articles de revues scientifiques et un chapitre de livre sur les défis métalinguistiques que rencontrent la langue française. Elle a participé en tant que conférencière invitée à deux événements et a présenté ses recherches sociolinguistiques et lexicographiques lors de 15 conférences en Europe et en Amérique.',
    },
  },
  jeff: {
    name: 'Jeffrey Liberman',
    title: {
      en: 'Senior Director of Sales and Marketing, GENAIZ',
      fr: 'Directeur sénior ventes et marketing, GENAIZ',
    },
    bio: {
      en:
        'In his role at GENAIZ, Jeffrey designs and implements strategic plans to expand the customer base and ensure a strong industry presence. Jeffrey has worked for over 20 years in the technology industry to offer clients worldwide tailored solutions, and to fulfill his deep commitment to customer satisfaction.',
      fr:
        "Dans son rôle chez GENAIZ, Jeffrey conçoit et met en œuvre des plans stratégiques pour élargir la clientèle et assurer une forte présence dans l'industrie. Jeffrey a travaillé pendant plus de 20 ans dans l'industrie de la technologie pour offrir à ses clients des solutions sur mesure dans le monde entier et pour remplir son engagement profond envers la satisfaction de la clientèle.",
    },
  },
  elyes: {
    name: 'Elyes Lamouchi',
    title: {
      en: 'Artificial Intelligence Scientist – NLP, GENAIZ',
      fr: 'Artificial Intelligence Scientist – NLP, GENAIZ',
    },
    bio: {
      en:
        'As an AI Research Scientist, Elyes develops natural language processing solutions by leveraging the latest developments in machine learning research. With a solid foundation in mathematics, statistics and computer science, Elyes’ multidisciplinary expertise allows GENAIZ to help its customers solve a variety of complex business problems, and to gain new types of insights, more quickly and effectively.',
      fr:
        'As an AI Research Scientist, Elyes develops natural language processing solutions by leveraging the latest developments in machine learning research. With a solid foundation in mathematics, statistics and computer science, Elyes’ multidisciplinary expertise allows GENAIZ to help its customers solve a variety of complex business problems, and to gain new types of insights, more quickly and effectively.',
    },
  },
  wilfred: {
    name: 'Wilfred Yau',
    title: {
      en: 'Software Engineer, GENAIZ',
      fr: 'Software Engineer, GENAIZ',
    },
    bio: {
      en:
        "Wilfred completed his master's in computational linguistics at McGill University and specializes in applied AI research in the domain of Natural Language Processing (NLP). His research interests include automatic text generation and natural language semantics. Prior to entering AI research, Wilfred completed his bachelor’s in Economics at the University of Toronto and worked briefly in FinTech. As a computational linguist, Wilfred aspires to allow an opportunity for his two distinct, though compatible capabilities within mathematics and linguistics to integrate and inform one another via his research.",
      fr:
        "Wilfred completed his master's in computational linguistics at McGill University and specializes in applied AI research in the domain of Natural Language Processing (NLP). His research interests include automatic text generation and natural language semantics. Prior to entering AI research, Wilfred completed his bachelor’s in Economics at the University of Toronto and worked briefly in FinTech. As a computational linguist, Wilfred aspires to allow an opportunity for his two distinct, though compatible capabilities within mathematics and linguistics to integrate and inform one another via his research.",
    },
  }
};
