/** @jsx jsx */
import { jsx, Styled, ThemeProvider } from 'theme-ui';
import Img from 'gatsby-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { mdxTheme } from '../theme';
import { getTypeLabel } from '../utils/articles';
import createGet from '../utils/create-get';
import useCurrentLanguage from '../hooks/use-current-language';

import headerTechSvg from '../img/headers/header-tech.svg';

import Layout from '../components/Layout';
import Container from '../components/Container2';
import AspectRatioImg from '../components/AspectRatioImg';
import ArticleTools from '../components/ArticleTools';
import AuthorBox from '../components/AuthorBox';

export const ArticlePageTemplate = ({ get, sourceType, body, post }) => {
  const lang = useCurrentLanguage();

  return (
    <Container>
      <ThemeProvider theme={mdxTheme}>
        <div
          sx={{
            fontSize: ['12px', null, '16px'],
            fontWeight: 'bold',
            lineHeight: 'body',
            textTransform: 'uppercase',
            mb: 2,
          }}
        >
          {getTypeLabel(sourceType, lang)}
        </div>
        {sourceType === 'tech-posts' ? (
          <AspectRatioImg src={headerTechSvg} alt="" ratio={912 / 256} />
        ) : (
          <Img
            fluid={get('cover.childImageSharp.fluid')}
            loading="eager"
            fadeIn={false}
          />
        )}
        <Styled.h1
          sx={{
            mt: 10,
            mb: [4, null, 7],
          }}
        >
          {get('title')}
        </Styled.h1>
        {get('subtitle') && (
          <div
            sx={{
              fontSize: ['16px', null, '28px'],
              fontWeight: 'heading',
              lineHeight: 'heading',
              mb: [4, null, 7],
            }}
          >
            {get('subtitle')}
          </div>
        )}
        <div sx={{ display: 'flex', ml: -2, mb: 7 }}>
          <ArticleTools post={post} />
        </div>
        <div
          sx={{
            '.gatsby-img-attributes': {
              display: 'block !important',
              margin: '0 auto',
            },
          }}
        >
          <MDXRenderer>{body}</MDXRenderer>
        </div>
      </ThemeProvider>
      {get('author') && <AuthorBox authorKey={get('author')} sx={{ mt: 11 }} />}
      {sourceType === 'news-posts' && (
        <div sx={{ textAlign: 'center' }}>###</div>
      )}
    </Container>
  );
};

const ArticlePage = ({ data }) => {
  const { frontmatter, body, slug, fields } = data.mdx;

  useEffect(() => {
    fetch(`/.netlify/functions/add-view?id=${slug}`)
      .then((response) => response.json())
      .then((data) => {
        // setCount(data.count || 0);
      })
      .catch((err) => {
        // ignore
      });
  }, [slug]);

  return (
    <Layout
      page={fields.sourceInstanceName}
      seo={{
        title: frontmatter.title,
        description: frontmatter.description,
      }}
    >
      <ArticlePageTemplate
        get={createGet(frontmatter)}
        body={body}
        post={data.mdx}
        sourceType={fields.sourceInstanceName}
      />
    </Layout>
  );
};

ArticlePage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ArticlePage;

export const pageQuery = graphql`
  query ArticlePageQuery($id: String!) {
    mdx(id: { eq: $id }) {
      fields {
        sourceInstanceName
      }
      frontmatter {
        title
        subtitle
        description
        author
        cover {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      id
      body
      timeToRead
      slug
    }
  }
`;
