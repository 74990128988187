/** @jsx jsx */
import { jsx, Styled, AspectRatio } from 'theme-ui';

import authors from '../utils/authors';
import useCurrentLanguage from '../hooks/use-current-language';

import profileNeilImg from '../img/profiles/profile-neil.png';
import profileJeffImg from '../img/profiles/profile-jeff.png';
import profileJovanaImg from '../img/profiles/profile-jovana.png';

const profileImageMap = {
  neil: profileNeilImg,
  jeff: profileJeffImg,
  jovana: profileJovanaImg,
};

const Autorbox = ({ authorKey, ...props }) => {
  const lang = useCurrentLanguage();
  const author = authors[authorKey];
  return (
    <aside
      {...props}
      sx={{
        bg: 'gray1',
        p: 4,
        borderRadius: '8px',
      }}
    >
      <div
        sx={{
          textTransform: 'uppercase',
          fontSize: '12px',
          letterSpacing: '0.08em',
        }}
      >
        {lang === 'fr' ? 'Auteur' : 'Author'}
      </div>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: profileImageMap[authorKey]
            ? '96px auto'
            : 'auto',
          alignItems: 'center',
          gap: 6,
          mt: 5,
        }}
      >
        {profileImageMap[authorKey] && (
          <AspectRatio
            ratio={1}
            sx={{
              borderRadius: '9999px',
              overflow: 'hidden',
            }}
          >
            <img src={profileImageMap[authorKey]} alt={author.name} />
          </AspectRatio>
        )}
        <div>
          <Styled.h3>{author.name}</Styled.h3>
          <div sx={{ fontSize: '14px', mt: 1 }}>{author.title[lang]}</div>
        </div>
      </div>
      <div sx={{ mt: 7 }}>{author.bio[lang]}</div>
    </aside>
  );
};

export default Autorbox;
